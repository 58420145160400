
import { Component, Vue } from 'vue-property-decorator'
import { IssueDetail, IssueTypeDetail } from '../../types/inspection.d'
import { dayFormat } from '../../utils/formatDate'
@Component({
  name: 'List'
})
export default class List extends Vue {
  private searchInfo: { issueStatus: string; issueTypeId: string; rectifierName: string } = {
    issueStatus: '',
    issueTypeId: '',
    rectifierName: ''
  }

  private tableData: IssueDetail[]=[]

  private loading = false
  private issueStatusList = []
  private issueTypeList: Array<IssueTypeDetail> = []
  private page = 1
  private size = 10
  private total = 0
  private countDownObj: any = {}
  // 定时器存储
  private intervalData: any = {}
  private props = {
    label: 'issueTypeName',
    children: 'childNode',
    value: 'issueTypeId'
  }

  get projectList () {
    return this.$store.state.projectList
  }

  get patrolId () {
    return this.$route.params.patrolId as string || ''
  }

  created (): void {
    this.getList()
    this.getStatusList()
    this.getTypeList()
  }

  destroyed () {
    // for (const key in this.intervalData) {
    //   this.intervalData[key] && clearInterval(this.intervalData[key])
    // }
  }

  getStatusList () {
    this.$axios.get(this.$apis.inspection.selectIssueStatusList).then(res => {
      this.issueStatusList = res || []
    })
  }

  // 查询类型列表
  getTypeList () {
    this.$axios.get<Array<IssueTypeDetail>>(this.$apis.inspection.selectIssueTypeByList).then(res => {
      this.issueTypeList = res
    })
  }

  // 获取列表数据
  getList (): void {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<IssueDetail> }>(this.$apis.inspection.selectIssueByPage,
      {
        patrolId: this.patrolId,
        ...this.searchInfo,
        page: this.page,
        size: this.size
      }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
      res.list.map((item: IssueDetail) => {
        if (item.issueId && +this.getExtraTime(item.issueId) > 0) {
          this.setTime(item.issueId)
        }
      })
    }).finally(() => {
      this.loading = false
    })
  }

  // 问题删除
  delIssue (row: IssueDetail) {
    this.$confirm('确定要删除该问题吗？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.inspection.deleteIssue,
        {
          issueId: row.issueId
        }).then(() => {
        this.$message.success('删除成功')
        this.onSearch()
      })
    })
  }

  addIssue () {
    this.$router.push({ name: 'issueAdd' })
  }

  // 查看详情
  toDetail (row: IssueDetail) {
    this.$router.push({ name: 'issueDetail', params: { issueId: row.issueId as string } })
  }

  onSearch (): void {
    this.page = 1
    this.getList()
  }

  // 催办点击
  onUrge (id: string) {
    this.$axios.post(this.$apis.inspection.insertIssueUrge, {
      issueId: id
    }).then(() => {
      this.$message.success('催办成功')
      this.setTime(id)
    })
  }

  // 获取存储时间
  getExtraTime (id: string) {
    return sessionStorage.getItem('issue' + id) || 0
  }

  // 倒计时
  setTime (id: string) {
    this.intervalData[id] && clearInterval(this.intervalData[id])
    if (!this.countDownObj[id]) {
      if (+this.getExtraTime(id) > 0) {
        this.$set(this.countDownObj, id, +this.getExtraTime(id))
      } else {
        this.$set(this.countDownObj, id, 60)
        sessionStorage.setItem('issue' + id, '60')
      }
    }
    this.intervalData[id] = setInterval(() => {
      if (this.countDownObj[id] < 1) {
        delete this.countDownObj[id]
        clearInterval(this.intervalData[id])
        sessionStorage.removeItem('issue' + id)
      } else {
        this.countDownObj[id]--
        sessionStorage.setItem('issue' + id, this.countDownObj[id])
      }
    }, 1000)
  }

  // 整改日期是否标红
  deadLineColor (item: IssueDetail) {
    return this.$dayjs(dayFormat(new Date().getTime())).isAfter(this.$dayjs(item.deadLine)) && item.issueStatus !== '2' && item.issueStatus !== '4' && item.issueStatus !== '5' ? 'red' : ''
  }
}
